import React from "react"
import { Container, Nav } from "react-bootstrap"
import { StyledLink } from "./StyledLink"

const SolutionNav = () => {
  return (
    <Container className="solution-nav">
      <Nav className="ml-auto pr-md-5 mr-md-5">
        <StyledLink to="/use-cases/incubator">Incubators</StyledLink>
        <StyledLink to="/use-cases/accelerator">Accelerator</StyledLink>
      </Nav>
    </Container>
  )
}

export default SolutionNav
