import React from "react"
import { Container, Image } from "react-bootstrap"
// styles
import "./styles.scss"

const index = ({ icon, title, description, width, height }) => {
  return (
    <Container fluid className="grid-item">
      <Image src={icon} alt={title + " icon"} width={width} height={height} />
      <h5>{title}</h5>
      <p>{description}</p>
    </Container>
  )
}

export default index
