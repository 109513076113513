import React from "react"
import { Container } from "react-bootstrap"
import ChartLine from "../../../static/images/Chart-line.svg"
import Equalizer from "../../../static/images/Equalizer.svg"
import Magic from "../../../static/images/Magic.svg"
import StartupsLaunch from "../../../static/images/StartupsLaunch.svg"
import ThunderMove from "../../../static/images/Thunder-move.svg"
import Cta from "../../components/cta"
import Footer from "../../components/footer"
import GridContainer from "../../components/gridContainer"
import GridItem from "../../components/gridItem"
import Layout from "../../components/layout"
import LogoCloud from "../../components/LogoClouds"
import NavBar from "../../components/navBar"
import Seo from "../../components/seo"
import SolutionNav from "../../components/SolutionNav"
import NewHero from "../../components/hero/newHero";

const Accelerator = ({ location }) => {
  return (
    <Layout>
      <Seo title="Team Coaches" />
      <NavBar location={location} />
      <Container>
        <SolutionNav isActive={2} />
        <NewHero
          title="Scale Your Accelerator Programs With Team Coaches"
          description="Get more visibility over your accelerator programs and better insights, so you can work more efficiently and maximize startup growth."
          image={StartupsLaunch}
        />
        <LogoCloud />
        <GridContainer>
          <GridItem
            icon={ThunderMove}
            width="67"
            height="67"
            title="Strengths &amp; Weakness"
            description="Understand startups and create goals to align with their needs."
          />
          <GridItem
            icon={Equalizer}
            width="66"
            height="66"
            title="Grow or Scale"
            description="Help grow or scale with both tools and mentors."
          />
          <GridItem
            icon={ChartLine}
            width="63"
            height="63"
            title="Investor Bootstrap"
            description="Create your deck with all your metrics, and with the right help."
          />
          <GridItem
            icon={Magic}
            width="59"
            height="59"
            title="Plan your Future"
            description="Create future goals and objectives to define your requirements."
          />
        </GridContainer>
        <Cta
          backgroundColor="back-blue"
          buttonColor="blue"
          title="Make Unicorns"
          points={["Unicorns are made not born."]}
          buttonText="Let's Talk"
        />
        <Footer />
      </Container>
    </Layout>
  )
}

export default Accelerator
