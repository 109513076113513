import React from "react"
import { Container } from "react-bootstrap"
import "./styles.scss"

const index = ({ children }) => {
  return (
    <Container fluid className="grid-container">
      {children}
    </Container>
  )
}

export default index
