import PropTypes from "prop-types"
import React, {useContext} from "react"
import {Button, Col, Container, Image, NavLink, Row} from "react-bootstrap"
import { ModalContext } from "../layout";
import "./styles.scss"

const NewHero = (
    {
        title, heroClasses, descClasses, descStyles,
        description, extraDescription, extraDescStyles,
        altText, image, isFluid = false, isHome, isProduct,
        buttonText, isDemoPage, style, demoButton = true
    }) => {

    const [, setShowModal] = useContext(ModalContext)
    const openModal = () => setShowModal(true)


    return (
        <Container fluid={isFluid || isHome} className={isHome? 'hero-home-page' : ''}>
            <Container fluid={isFluid}>
                <Row
                    className={isHome ? 'm-0 d-flex justify-content-between align-items-center' : `m-0 align-items-center justify-content-center justify-content-md-between hero-container${
                        isHome ? "-home" : ""
                    } ${heroClasses}`}
                >
                    <Col xs={12} md={isFluid ? 10 : 12} className="mx-auto">
                        <Row className={isFluid ? 'd-flex align-items-center' : ''}>
                            <Col
                                fluid
                                id="hero-text"
                                className="px-3 text-center text-md-start"
                                xs={12}
                                md={isHome ? 5 : 6}
                                style={{ flexGrow: 1}}
                            >
                                <h1
                                    className={`h1 ${isHome ? 'text-wrap w-100' : ''}`}
                                    style={isProduct ? { color: "#ffffff" } : undefined}
                                >
                                    {title}
                                </h1>
                                <p
                                    className={`py-2 ${descClasses} ${isHome ? 'text-wrap w-100' : ''}`}
                                    style={{ color: (isProduct ? "#ffffff" : isHome? '#393939' : undefined ), ...descStyles}}
                                >
                                    {description}
                                </p>
                                {extraDescription ? <p style={extraDescStyles}>{extraDescription}</p> : "" }
                                { isDemoPage ? (
                                    <NavLink href="#book-a-zcal-demo" className="px-0">
                                        <Button>
                                            {buttonText ? buttonText : "Request A Demo"}
                                        </Button>
                                    </NavLink>
                                ) : (
                                    <Button onClick={openModal} style={{ display: demoButton ? 'block' : 'none'}}>
                                        {buttonText ? buttonText : "Book A Demo"}
                                    </Button>
                                )}
                            </Col>
                            <Col
                                fluid
                                className={`px-3 pb-4 pb-sm-0 ${isHome || isFluid ? '' : 'hero-group-background'}`}
                                style={isHome || isFluid ? { display: 'flex', justifyContent: 'end' } : {}}
                                xs={12}
                                md={isHome ? 7 : 6}
                            >
                                {image ? (
                                    <Image
                                        fluid
                                        rounded
                                        src={image}
                                        alt={altText || ""}
                                        style={{ maxHeight: isHome ? "400px" : "100%", margin: isHome ? '4em 0' : undefined }}
                                        className="d-none d-sm-block"
                                    />
                                ) : null}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

NewHero.propTypes = {
    siteTitle: PropTypes.string,
}

NewHero.defaultProps = {
    siteTitle: ``,
}

export default NewHero
