import React, { useContext } from "react"
import { Container, Row, Col } from "react-bootstrap"
//import { CheckIn } from "../Icons"
// styles
import "./styles.scss"
// Modal
import { ModalContext } from "../layout"

/*
 * Possible Inputs for Params/Props
 ** backgroundColor: back-blue / back-gray
 ** buttonColor: blue / gray
 */
const Cta = ({ backgroundColor, buttonColor, title, points, buttonText }) => {
  const [, setShowModal] = useContext(ModalContext)
  const handleShow = () => setShowModal(true)
  return (
    <Container className={"cta-container " + backgroundColor}>
      <Row
        style={{
          flexDirection: "column",
          justifyContent: "center",
          justifyItems: "center",
          textAlign: "center",
          alignItems: "center",
        }}
        fluid="md"
        className="mx-0"
      >
        <p className="h2 cta-title">{title}</p>
        <Col>
          <button className={buttonColor} onClick={handleShow}>
            {buttonText || "Let's Talk"}
          </button>
        </Col>
        {/* <Row className="mb-4">
          {points.map((point) => (
            <Col key={point} sm className="highlights__hl-item">
              <CheckIn width="18" height="18" />
              <span className="h6">{point}</span>
            </Col>
          ))}
        </Row> */}
      </Row>
    </Container>
  )
}

export default Cta
